<template>
  <div class="search_filter_wrap no_bg">
    <div class="search_item_wrap">
      <div class="end_filter" >
        <!--        <button class="filter_btn two" @click="button>-->
        <!--
                    popper-class="itemFilterMyPage"
        -->
        <el-popover
            popper-class="top_500"
            placement=""
            width="435"
            trigger="click"
            :open-delay="300"
            :offset="0"
            :append-to-body="false"
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
          <el-button slot="reference" class="filter_btn two"></el-button>
          <div class="popup_tail"></div>
          <div class="popup_bg">
            <div class="popup_inner">
              <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
              <div class="inner_middle">
<!--                <el-checkbox-group v-model="filterStatus"
                                   @change="  setFilterFunc('imst', filterStatus)">
                  <el-checkbox :label="'auction'">
                    {{ $t('auction') }}
                  </el-checkbox>
                  <el-checkbox :label="'license'">
                    {{ $t('license_sell') }}
                  </el-checkbox>
                  <el-checkbox :label="'fix'">
                    {{ $t('fix_selling') }}
                  </el-checkbox>
                  <el-checkbox :label="'no'">
                    {{ $t('no_sell') }}
                  </el-checkbox>
                  <el-checkbox :label="'competition'">
                    {{ $t('competition_apply') }}
                  </el-checkbox>
                </el-checkbox-group>-->
                <div class="sub_title">{{$t('mingle')}} {{$t('modification')}}</div>
                <div class="edition_wrap">
                  <el-checkbox-group v-model="mingleArr"
                                     @change="setFilterFunc('post', mingleArr)">
                    <el-checkbox :label="'plus'">{{$t('increase')}}</el-checkbox>
                    <el-checkbox :label="'minus'">{{$t('decrease')}}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="sub_title">{{$t('status')}}</div>
                <div class="status_wrap">
                  <el-checkbox-group v-model="filterStatus"
                                     @change="setFilterFunc('stg', filterStatus)">
                    <el-checkbox :label="'auction'">{{$t('auction')}}</el-checkbox>
                    <el-checkbox :label="'deal'">{{$t('deal')}}</el-checkbox>
                    <el-checkbox :label="'competition'">{{$t('competition')}}</el-checkbox>
                    <el-checkbox :label="'mingle'">{{$t('charge')}}/{{$t('withdraw')}}</el-checkbox>
                    <el-checkbox :label="'etc'">{{$t('etc')}}</el-checkbox>
                  </el-checkbox-group>
<!--                  3. 필터 내 상태 경매, 거래, 공모전, 충전/출금, 기타로 수정 ->안됨-->
                </div>
                <div class="sub_title">{{$t('date')}}</div>
                <div class="price_wrap">
                  <el-date-picker
                      class="range_time"
                      v-model="filterDate"
                      type="daterange"
                      range-separator="~"
                      value-format="yyyy-MM-dd"
                      @change="changeDateFilter()"
                      start-placeholder="YYYY-MM-DD"
                      end-placeholder="YYYY-MM-DD"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
        </el-popover>
      </div>

    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";
import pointUtil from "@/components/point/pointUtil";

const {filterConfig} = pointUtil;
export default {
  name: "PointFilterDefaultLayout",
  mixins: [pointUtil],
  components: {
  },
  inject: ['setFilter', 'setSort'],
  provide() {
    return{}
  },
  props: {
    filterData:{default: () => {}},
  },
  data() {
    return{
      mingleArr: [],
      filterStatus: [],
      filterDate: [],
    }
  },
  beforeRouterEnter() {},
  created() {
    this.setterFilterDataInData();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    setterFilterDataInData() {
      let classnames = Object.keys(this.pointData);
      classnames.forEach(classname => {
        let obj = this.pointData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    setData(key, value) {
      this[key] = value;
    },
    changeDateFilter() {
      if(util.isEmpty(this.filterDate)) {
        this.setFilter('cr', '');
      }else{
        let date = this.filterDate;
        date[0] = `${date[0]} 00:00:00`;
        date[1] = `${date[1]} 23:59:59`;
        this.setFilter('cr', date);
      }
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
